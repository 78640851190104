<template>
  <div id="app">
    <PageHeader v-if="!$root.siteOffline" />
    <router-view v-if="!$root.siteOffline" />
    <Offline v-if="$root.siteOffline" />
  </div>
</template>

<script>
import { prismicApi } from './cms'
import * as en from './translations/en'
import * as da from './translations/da'
import analyticsEvents from './analytics/analyticsEvents'
import PageHeader from './components/Header/Header.vue'
import Offline from './components/Offline.vue'

export default {
  name: 'App',
  components: { PageHeader, Offline },
  mixins: [prismicApi],
  data() {
    const l = import.meta.env.VITE_MULTILANGUAGE !== 'false' ? localStorage.getItem('language') || 'da' : 'da'

    return {
      language: l,
      languageResources: {},
      user: null,
      SDKLoaded: false,
      dynamicData: null,
      siteOffline: false
    }
  },
  computed: {
    localizedRoutes() {
      return this.languageResources.ROUTES_TRANSLATIONS ?? {}
    },
    analyticsEvents() {
      return analyticsEvents
    }
  },
  watch: {
    language() {
      this.setLanguage()
    },
    $route() {
      this.setHubspotVisibility()
    }
  },
  beforeMount() {
    this.setLanguage()
    const localDetails = this.localizedRoutes[this.$route.name] ?? this.localizedRoutes.Home
    this.setPageTitle(localDetails.title, localDetails.description)
    this.setCanonical()
  },
  mounted() {
    this.loadCookiebot()
    this.enableTracking()
    this.loadSDK()
    this.loadAppNexusConversionMarker()
    this.checkStatus()
    if (import.meta.env.VITE_ENV_LABEL === '') this.loadClarity(window, document, 'clarity', 'script', 'gmqvtf7sm9', null, null)
    // setTimeout(this.loadHubspot, 5000)
  },
  methods: {
    async getDynamicContent(type, uid) {
      const document = await this.queryByUid(type, uid, this.language)
      this.dynamicData = document

      return document
    },
    setLanguage() {
      const translations = { en, da }
      document.querySelector('html').setAttribute('lang', this.language)
      this.languageResources = translations[this.language]
    },
    async loadSDK() {
      const components = ['search', 'favorites', 'saved-searches', 'calculator', 'carousel', 'trade-in', 'orders']
      const promises = components.map(c => window.customElements.whenDefined('seez-sdk-' + c))
      await Promise.allSettled(promises)
      window.seezSdk.getCurrentUser().then(user => (this.user = user))
      this.SDKLoaded = true
      this.trackSocial()
    },
    trackSocial() {
      const social_login = localStorage.getItem('social_login')
      if (social_login) {
        this.track(this.$root.analyticsEvents.LOGIN_SUCCESS, {
          event_name: this.$root.analyticsEvents.LOGIN_SUCCESS,
          event_category: 'signup_flow',
          event_action: this.$root.analyticsEvents.LOGIN_SUCCESS,
          label: '/',
          event_type: social_login
        })
        localStorage.removeItem('social_login')
      }
    },
    loadCookiebot() {
      if (import.meta.env.VITE_COOKIEBOT_ID == null || import.meta.env.VITE_ENV_LABEL === 'local') return

      const script = document.createElement('script')
      script.id = 'Cookiebot'
      script.src = 'https://consent.cookiebot.com/uc.js'
      script.dataset.cbid = import.meta.env.VITE_COOKIEBOT_ID
      script.dataset.blockingmode = 'auto'
      script.type = 'text/javascript'
      script.async = true

      document.head.appendChild(script)
    },
    startTracking() {
      localStorage.setItem('cookiebot_consent', 'true')
      window.seezSdk.enableTracking(import.meta.env.VITE_SEGMENT_ID)
    },
    enableTracking() {
      if (import.meta.env.NODE_ENV === 'development') {
        this.startTracking()
        return
      }
      window.addEventListener('CookiebotOnDecline', () => {
        console.log('rejected')
      })
      window.addEventListener(
        'CookiebotOnAccept',
        () => {
          if (window.Cookiebot.consent.statistics) {
            this.startTracking()
          }
        },
        false
      )
    },
    loadAppNexusConversionMarker() {
      setTimeout(() => {
        const script = document.createElement('script')
        script.src = 'https://secure.adnxs.com/px?id=1568198&t=1'
        script.type = 'text/javascript'
        script.async = true
        document.head.appendChild(script)

        const img = document.createElement('img')
        img.src = 'https://secure.adnxs.com/seg?add=29526928&t=2'
        img.className = 'trackingPixel'
        img.height = '1'
        img.width = '1'
        document.body.appendChild(img)
      }, 30000)
    },
    setPageTitle(title, description) {
      const siteTitle = this.localizedRoutes.site_title
      const defaultDetails = this.localizedRoutes.Home
      document.title = title ? `${title}  ·  ${siteTitle}` : defaultDetails.title
      document.head.querySelector('meta[name=description]').setAttribute('content', description ?? defaultDetails.description)
    },
    setCanonical() {
      document.head.querySelectorAll('link[rel=canonical]').forEach(c => c.parentNode.removeChild(c))
      const canonical = document.createElement('link')
      canonical.setAttribute('rel', 'canonical')
      canonical.setAttribute('href', window.location.origin + window.location.pathname)
      document.head.appendChild(canonical)
    },
    trackEvent(e) {
      const event = e.detail[0]
      const eventName = event[0]
      const properties = event[1]

      this.track(eventName, properties)
    },
    track(eventKey, properties, vData) {
      return window.seezSdk.track(eventKey, properties, vData)
    },
    trackCTA(eventName, properties) {
      return this.track(
        eventName,
        {
          event_category: properties.category,
          event_label: properties.name,
          target_site_id: properties.target_site_id,
          event_action: { iteraction: { navigation: { name: properties.name, type: properties.type } } },
          payload: properties.payload
        },
        { vehicle: properties.listing }
      )
    },
    loadHubspot() {
      const s = document.createElement('script')
      s.async = true
      s.src = '//js-eu1.hs-scripts.com/25578444.js'
      s.onload = () => {
        // setTimeout(() => {
        //   const hubspotButton = document.querySelector('#hubspot-messages-iframe-container')
        //   hubspotButton.addEventListener('click', async () => {
        //     this.$root.track(this.$root.analyticsEvents.LIVE_AGENT_BTN_CLICKED, { routeName: this.$route.name })
        //   })
        // }, 2000)
        this.setHubspotVisibility()
      }
      const t = document.scripts[document.scripts.length - 1]
      t.parentElement.insertBefore(s, t.nextSibling)
    },
    setHubspotVisibility() {
      const hubspotButton = document.querySelector('#hubspot-messages-iframe-container')
      if (hubspotButton) {
        hubspotButton.style.display = this.$route.name === 'Intro' ? 'none' : 'block'
      }
    },
    //converts a string into something healthy for urls
    slugify(text) {
      return text
        ?.trim()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .replace(/[^\w]+/g, '-')
        .replace(/-+$/g, '')
        .toLowerCase()
    },
    async checkStatus() {
      this.siteOffline = window.siteConfig?.platformOffline === true
    },
    loadClarity(c, l, a, r, i, t, y) {
      //Clarity is the Heatmap tool
      c[a] =
        c[a] ||
        function () {
          ;(c[a].q = c[a].q || []).push(arguments)
        }
      t = l.createElement(r)
      t.async = 1
      t.src = 'https://www.clarity.ms/tag/' + i
      y = l.getElementsByTagName(r)[0]
      y.parentNode.insertBefore(t, y)
    },
    formatNumber(number, decimals, currency) {
      if (number == null) return ''
      const options = {}
      if (decimals != null) Object.assign(options, { minimumFractionDigits: decimals, maximumFractionDigits: decimals })
      if (currency != null) Object.assign(options, { style: 'currency', currency, currencyDisplay: 'narrowSymbol' })
      return new Intl.NumberFormat(this.language, options).format(parseFloat(number))
    }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'Biennale';
  src:
    local('sans-serif'),
    url('@/assets/fonts/biennale-thin-webfont.woff2') format('woff2');
  font-weight: 200;
  font-display: fallback;
}

@font-face {
  font-family: 'Biennale';
  src:
    local('sans-serif'),
    url('@/assets/fonts/biennale-light-webfont.woff2') format('woff2');
  font-weight: 300;
  font-display: fallback;
}

@font-face {
  font-family: 'Biennale';
  src:
    local('sans-serif'),
    url('@/assets/fonts/biennale-regular-webfont.woff2') format('woff2');
  font-weight: 400;
  font-display: fallback;
}

@font-face {
  font-family: 'Biennale';
  src:
    local('sans-serif'),
    url('@/assets/fonts/biennale-medium-webfont.woff2') format('woff2');
  font-weight: 500;
  font-display: fallback;
}

@font-face {
  font-family: 'Biennale';
  src:
    local('sans-serif'),
    url('@/assets/fonts/biennale-bold-webfont.woff2') format('woff2');
  font-weight: 700;
  font-display: fallback;
}

@font-face {
  font-family: 'Biennale';
  src:
    local('sans-serif'),
    url('@/assets/fonts/biennale-black-webfont.woff2') format('woff2');
  font-weight: 800;
  font-display: fallback;
}

:root {
  --accent: #004679;
  --background: #ffffff;
  --background1: #f2f2f2;
  --background2: #d7d7d7;
  --bg-article-hover: rgba(204, 225, 255, 0.5);
  --font1: #545454;
  --font2: #1f1f1f;
  --max-content-width: 75rem;
  --max-content-width-slim: 60rem;
  --content-side-padding: max(2rem, calc(54vw - var(--max-content-width) / 2));
  --content-side-padding-wide: max(2rem, calc(50vw - var(--max-content-width) / 2));
  --highlight: #0068ff;
  --black: #000000;
  --white: #ffffff;
  --blue-shadow: 1px 4px 12px 4px rgba(204, 225, 255, 0.34);
  --light-blue: rgba(204, 225, 255, 0.3);
  --font-family: 'Biennale', sans-serif !important;
  --widget-box-shadow: 1px 2px 12px rgba(0, 0, 0, 0.12);

  @media screen and (min-width: 3840px) {
    --content-side-padding: max(2rem, calc(48vw - var(--max-content-width) / 2));
  }
}

*:not(.multiselect) {
  scrollbar-width: thin;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 7px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: rgba(255, 255, 255, 0.3) 0 0 0 1px;
}

*:hover::-webkit-scrollbar-thumb {
  background: #cccccc;
}

*::-webkit-scrollbar-thumb:hover {
  background: #cccccc;
}

html,
body {
  min-height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 16px;
  scroll-behavior: smooth;
  font-family: 'Biennale', sans-serif;
}

@media print {
  html,
  body {
    overflow: hidden;
    height: 100vh;
  }
}

a {
  text-decoration: none;
}

button {
  font-family: 'Biennale', sans-serif;
}

#app {
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--font1);
  display: grid;
  grid-template-rows: auto 1fr;
  place-items: stretch;
  background-color: var(--background);

  .offline {
    grid-row-end: span 2;
  }

  @media print {
    min-height: auto;
    grid-template-rows: 1fr;
  }
}

.trackingPixel {
  position: fixed;
  bottom: 0;
  right: 0;
  opacity: 0;
}

seez-sdk-login-form .seezLogin {
  margin: -1rem;
}

seez-sdk-login-form div[slot='branding'] {
  background: linear-gradient(170.4deg, #0068ff -3.83%, #9e80ff 111.41%);
  display: grid;
  align-items: end;
}

seez-sdk-comparison-table {
  .feature {
    background-color: green;
    color: var(--background);
  }

  .comparisonTable {
    font-size: 0.75rem;
    border-collapse: separate;
    border-spacing: 12px 0;
    table-layout: fixed;
    transform: translate(-12px);

    th,
    td {
      padding: 0.3rem;
      max-width: 14rem;
    }

    tr:nth-child(2) td {
      padding: 0 0.3rem 1rem 0.3rem;
    }

    th:not(:first-child),
    td:not(:first-child) {
      min-width: 12rem;
    }

    th > img {
      width: 100%;
      height: auto !important;
      aspect-ratio: 16 / 10;
      object-fit: cover;
      border-radius: 6px;
    }

    td:nth-child(1) {
      background: var(--chat-comparison-table-bg);
      font-size: 0.625rem;
    }

    td:not(:first-of-type) {
      color: black;
      transform: translateX(-0.7rem);
      background-color: white;
    }

    td:nth-child(2),
    td:nth-child(3),
    th:nth-child(2),
    th:nth-child(3) {
      border-left: 1px solid #ececec;
      border-right: 1px solid #ececec;
    }

    th:nth-child(2),
    th:nth-child(3),
    th:nth-child(4) {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }

    th:not(th:first-child) {
      background-color: white;
      border-top: 1px solid #ececec;
      transform: translateX(-0.7rem);
    }

    td:last-child,
    th:last-child {
      border-right: 1px solid #ececec;
      border-left: 1px solid #ececec;
    }
    tr:first-child {
      font-weight: 600;
    }

    tr:nth-child(2) {
      font-weight: 400;
      font-size: 0.625rem;
    }

    tr:nth-child(0) td:first-child,
    tr:nth-child(1) td:first-child,
    tr:nth-child(2) td:first-child,
    tr:nth-child(6) td:first-child {
      background-color: transparent;
      font-weight: 600;
    }

    tr:last-child td:not(td:first-child) {
      border-bottom: 1px solid #ececec;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  }
}

seez-sdk-carousel .carousel {
  > .actions > button {
    border-radius: 3em;
    border: 1px solid #cccccc;
    font-size: 1em;
    padding: 0.75rem 1.5rem;
    cursor: pointer;
    background: none;

    &:last-child {
      border: none;
      color: var(--accent);
    }
  }

  > .listings.grid {
    grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  }
}
</style>
